import * as React from "react";
import loadable from '@loadable/component'

import {
  homepageTitle,
  couchSectionHomepage,
  insuranceCard,
  fullParagraphContent,
  checkTheme
} from "../utils/informations";
import HomeInsCard from "../components/homepage-insurance-card/homepage-insurance-card";

import '../styles/global.scss'



const Layout = loadable(() => import("../layouts"))

const homepageDescription = loadable(() => import("../utils/informations"))
const plantImg = loadable(() => import("../utils/informations"))
const fcaImg = loadable(() => import("../utils/informations"))
const bibaImg = loadable(() => import("../utils/informations"))



const Header = loadable(() => import("../components/homepage-header/homepage-header"))
const HomeInsurances = loadable(() => import("../components/home-insurances/home-insurances"))
const Insurance = loadable(() => import("../components/homepage-insurances/homepage-insurances"))


const FullScreenParagraph = loadable(() => import("../components/fullscreen-paragraph/fullscreen-paragraph"))

const Couch = loadable(() => import("../components/couch/couch"))


const fragment = '#products'


const IndexPage = () => (



  <Layout pageTitle={`${checkTheme().pageTitle} - Saving you both time and money`}>
    <Header
      title={homepageTitle}
      description={homepageDescription}
      plant={plantImg}
      fca={fcaImg}
      biba={bibaImg}

    />


    <Insurance fragment={fragment} />
    <HomeInsurances />

    {
      checkTheme().review ?
        <div className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="5cd2b9fdc6d1db000155ed51" data-style-height="24px" data-style-width="100%" data-theme="light" data-min-review-count="10" data-style-alignment="center">
          <a href="https://uk.trustpilot.com/review/savemoneyhub.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>

        : ""
    }





    <HomeInsCard props={insuranceCard} />

    <Couch props={couchSectionHomepage} />

    <FullScreenParagraph props={fullParagraphContent} origin="Homepage" />









  </Layout>
);

export default IndexPage;
